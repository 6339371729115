import { NotyfService } from '../services/notyf.service';
import { Observable } from "rxjs";
export async function handle(
  allFn: () => Observable<any>,
  notyfService: NotyfService,
): Promise<void> {
  try {
    const res = await allFn().toPromise();
    if (res?.error) {
      notyfService.showToast("error", res.message, "toast-danger");
    } else {
      return res;
    }
  } catch (err) {
    notyfService.showToast("error", err.error.message, "toast-danger");
    throw err;
  }
}

export async function handle1(
  allFn: () => Observable<any>,
  notyfService: NotyfService,
): Promise<void> {
  try {
    const res = await allFn().toPromise();
    if (res?.error) {
      notyfService.showToast("error", res.message, "toast-danger");
    } else {
      return res;
    }
  } catch (err) {
    notyfService.showToast("error", err.error.message, "toast-danger");
    throw err;
  }
}
