<div class="page" [class.loderhide]="!show">
  <div class="loader-wrapper">
    <div class="logo_lvb">
      <div class="center">
        <img class="ml-1_5" width="250" alt="Logo" />
      </div>
    </div>
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
