<div
  class="sidebar-area bg-white box-shadow dark-sidebar"
  [ngClass]="{ active: isToggled }"
>
  <div class="logo">
    <a routerLink="/dashboard" class="d-flex align-items-center">
      <img width="200" alt="Logo" />
    </a>
  </div>
  <div
    class="burger-menu"
    (keydown)="handleKeydown($event)"
    (click)="toggle()"
    [ngClass]="{ active: isToggled }"
  >
    <span class="top-bar"></span>
    <span class="middle-bar"></span>
    <span class="bottom-bar"></span>
  </div>

  <ng-scrollbar visibility="hover" style="height: 100vh">
    <div class="sidebar-inner">
      <div class="sidebar-menu">
        <mat-accordion>
          <ng-container *ngFor="let menuItem of menuItems">
            <div *ngIf="!menuItem.hidden">
              <a
                [routerLink]="menuItem.link"
                *ngIf="menuItem.type === 'link'"
                class="sidebar-menu-link cursor-pointer"
                routerLinkActive="active"
              >
                <i [class]="menuItem.icon"></i>
                <span class="title">{{ menuItem.title | translate }}</span>
              </a>
              <span
                class="sub-title gray-color mt-4"
                *ngIf="menuItem.headTitle"
                >{{ menuItem.headTitle | translate }}</span
              >
              <mat-expansion-panel
                (opened)="panelOpenState = true"
                (closed)="panelOpenState = true"
                *ngIf="
                  menuItem.type === 'sub' &&
                  menuItem.subItems &&
                  menuItem.subItems.length > 0
                "
                [hidden]="menuItem.hidden"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <i [class]="menuItem.icon"></i>
                    <span class="title">{{ menuItem.title | translate }}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ul class="sidebar-sub-menu">
                  <li
                    class="sidemenu-item"
                    *ngFor="let subItem of menuItem.subItems"
                  >
                    <a
                      [routerLink]="subItem.link"
                      routerLinkActive="active"
                      class="sidemenu-link cursor-pointer"
                    >
                      {{ subItem.title | translate }}
                    </a>
                  </li>
                </ul>
              </mat-expansion-panel>
            </div>
          </ng-container>
        </mat-accordion>
      </div>
    </div>
  </ng-scrollbar>
</div>
