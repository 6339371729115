<div
  class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between"
>
  <ol class="breadcrumb mb-0 ps-0">
    <li class="breadcrumb-item">
      <a routerLink="/dashboard">
        <i class="ri-home-8-line"></i>
        {{ breadcrumb?.first | translate }}
      </a>
    </li>
    <li class="breadcrumb-item fw-semibold">
      {{ breadcrumb?.second | translate }}
    </li>
    <li class="breadcrumb-item fw-semibold" *ngIf="breadcrumb?.third">
      {{ breadcrumb?.third | translate }}
    </li>
  </ol>
</div>
