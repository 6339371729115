<div class="row">
  <div class="col-lg-6 mt-15">
    <h3>{{ "STAT.TITLE_DASH" | translate }}</h3>
  </div>
</div>
<div class="row mt-10" *ngIf="listInfoDashb; else skeletonLoader">
  <div class="p-grid p-fluid dashboard-cards">
    <div class="row">

      <div class="col-md-4">
        <div class="stat_box" [ngStyle]="{backgroundColor: '#34C759',border: '1px solid #FFF'}">
          <div class="stat_box__content single-stats-box patrimoine-sim-card mb-25">
            <div class="d-flex align-items-center justify-content-space-between">
              <h1 [ngStyle]="{ color: '#FFF' }">{{ pipeValue(listInfoDashb?.active_clients) }}</h1>
            </div>
            <h4 class="fw-semibold" [ngStyle]="{ color: '#FFF' }">{{"GeneralStrings.DASHBOARD.libelle_1" | translate}}</h4>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="stat_box" [ngStyle]="{backgroundColor: '#007AFF',border: '1px solid #FFF'}">
          <div class="stat_box__content single-stats-box patrimoine-sim-card mb-25">
            <div class="d-flex align-items-center justify-content-space-between">
              <h1 [ngStyle]="{ color: '#FFF' }">{{ pipeValue(listInfoDashb?.total_clients) }}</h1>
            </div>
            <h4 class="fw-semibold" [ngStyle]="{ color: '#FFF' }">{{"GeneralStrings.DASHBOARD.libelle_2" | translate}}</h4>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="stat_box" [ngStyle]="{backgroundColor: '#0A84FF',border: '1px solid #FFF'}">
          <div class="stat_box__content single-stats-box patrimoine-sim-card mb-25">
            <div class="d-flex align-items-center justify-content-space-between">
              <h1 [ngStyle]="{ color: '#FFF' }">{{ pipeValue(listInfoDashb?.total_companies) }}</h1>
            </div>
            <h4 class="fw-semibold" [ngStyle]="{ color: '#FFF' }">{{"GeneralStrings.DASHBOARD.libelle_3" | translate}}</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-md-4">
        <div class="stat_box" [ngStyle]="{backgroundColor: '#28A745',border: '1px solid #FFF'}">
          <div class="stat_box__content single-stats-box patrimoine-sim-card mb-25">
            <div class="d-flex align-items-center justify-content-space-between">
              <h1 [ngStyle]="{ color: '#FFF' }">{{ pipeValue(listInfoDashb?.total_dossiers) }}</h1>
            </div>
            <h4 class="fw-semibold" [ngStyle]="{ color: '#FFF' }">{{"GeneralStrings.DASHBOARD.libelle_4" | translate}}</h4>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="stat_box" [ngStyle]="{backgroundColor: '#FFD60A',border: '1px solid #FFF'}">
          <div class="stat_box__content single-stats-box patrimoine-sim-card mb-25">
            <div class="d-flex align-items-center justify-content-space-between">
              <h1 [ngStyle]="{ color: '#000' }">{{ pipeValue(listInfoDashb?.valid_dossiers) }}</h1>
            </div>
            <h4 class="fw-semibold" [ngStyle]="{ color: '#000' }">{{"GeneralStrings.DASHBOARD.libelle_5" | translate}}</h4>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="stat_box" [ngStyle]="{backgroundColor: '#8E8E93',border: '1px solid #FFF'}">
          <div class="stat_box__content single-stats-box patrimoine-sim-card mb-25">
            <div class="d-flex align-items-center justify-content-space-between">
              <h1 [ngStyle]="{ color: '#FFF' }">{{ pipeValue(listInfoDashb?.archived_dossiers) }}</h1>
            </div>
            <h4 class="fw-semibold" [ngStyle]="{ color: '#FFF' }">{{"GeneralStrings.DASHBOARD.libelle_6" | translate}}</h4>
          </div>
        </div>
      </div>

    </div>

    <div class="row">

      <div class="col-md-4">
        <div class="stat_box" [ngStyle]="{backgroundColor: '#FF3B30',border: '1px solid #FFF'}">
          <div class="stat_box__content single-stats-box patrimoine-sim-card mb-25">
            <div class="d-flex align-items-center justify-content-space-between">
              <h1 [ngStyle]="{ color: '#FFF' }">{{ pipeValue(listInfoDashb?.rejected_dossiers) }}</h1>
            </div>
            <h4 class="fw-semibold" [ngStyle]="{ color: '#FFF' }">{{"GeneralStrings.DASHBOARD.libelle_7" | translate}}</h4>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #skeletonLoader>
  <ngx-skeleton-loader count="17"></ngx-skeleton-loader>
</ng-template>
