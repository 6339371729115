import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { DashboardService } from "../data-access/services/dashboard.service";
import { NotyfService } from "@/shared/services/notyf.service";
import { handle } from "@/shared/functions/api.function";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  public listInfoDashb: any;
  public response: any = {};

  private subscriptions: Subscription = new Subscription();
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private notyfService: NotyfService,
    private dashboardService: DashboardService
  ) {
    this.subscribeToRouteData();
  }


  async ngOnInit() {
    await this.getStatisticsDashboard();
  }

  public async getStatisticsDashboard(): Promise<void> {
    const response: any = await handle(() => this.dashboardService.getAllInfoDashboard(), this.notyfService);
    if (response.error === false) this.listInfoDashb = response?.data;
  }

  pipeValue(number: any) {
    return new Intl.NumberFormat("fr-FR").format(number);
  }

  private subscribeToRouteData(): void {
    this.subscriptions.add(
      this.route.data.subscribe((data) => {
        const title = data["title"] || "Default Title";
        this.titleService.setTitle(title);
      }),
    );
  }
}
