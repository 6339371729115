<div>
  <app-sidebar></app-sidebar>
  <div class="main-content d-flex flex-column" [ngClass]="{ active: isToggled }">
    <div class="pl-4">
      <app-header></app-header>
      <router-outlet></router-outlet>
    </div>
    <div class="flex-grow-1"></div>
    <app-footer></app-footer>
  </div>
</div>
