export const sidebarMenuAdmin: any[] = [
  {
    title: "MENU.0.title",
    icon: "flaticon-home",
    link: "/dashboard",
    type: "link",
  },
  {
    headTitle: "MENU.1.headTitle",
  },
  {
    title: "MENU.2.title",
    icon: "flaticon-folder-1",
    type: "sub",
    subItems: [
      {
        id: 1,
        title: "MENU.2.subItems.0.title",
        link: "/patrimony/customers",
      },
      {
        id: 2,
        title: "MENU.2.subItems.1.title",
        link: "/patrimony/folder",
      },
    ]
  },
  {
    title: "MENU.3.title",
    icon: "fas fa-trash-alt",
    type: "sub",
    subItems: [
      {
        id: 1,
        title: "MENU.3.subItems.0.title",
        link: "/trash/archived-folder",
      }
    ]
  },
  {
    title: "MENU.4.title",
    icon: "fas fa-envelope",
    link: "/messaging",
    type: "link",
  },
  {
    title: "MENU.5.title",
    icon: "fas fa-user-circle",
    link: "/subscriptions",
    type: "link",
  },
  {
    headTitle: "MENU.6.headTitle",
  },
  {
    title: "MENU.7.title",
    icon: "flaticon-settings",
    type: "sub",
    subItems: [
      {
        id: 1,
        title: "MENU.7.subItems.0.title",
        link: "/settings/admin",
      }
    ]
  },
];
