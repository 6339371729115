import { DashboardComponent } from "@/pages/dashboard/ui/dashboard.component";
import { Routes } from "@angular/router";


export const content: Routes = [

  {
    path: `dashboard`,
    component: DashboardComponent,
    data: {title : 'Tableau de bord | Découvrez les services de Bank Transfers.'}
  },
  {
    path: `patrimony`,
    loadChildren: () => import("src/app/pages/patrimoine/patrimoine.module").then((m) => m.PatrimoineModule,)
  },
  {
    path: `trash`,
    loadChildren: () => import("src/app/pages/corbeille/corbeille.module").then((m) => m.CorbeilleModule,)
  },
  {
    path: `messaging`,
    loadChildren: () => import("src/app/pages/messagerie/messagerie.module").then((m) => m.MessagerieModule,)
  },
  {
    path: `subscriptions`,
    loadChildren: () => import("src/app/pages/abonnements/abonnements.module").then((m) => m.AbonnementsModule,)
  },
  {
    path: `settings`,
    loadChildren: () => import("src/app/pages/administration/settings/settings.module").then((m) => m.SettingsModule,)
  },
  {
    path: '',
    redirectTo: `dashboard`,
    pathMatch: 'full'
  },
];
