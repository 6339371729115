<div class="h-100vh pt-50 pb-50">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="internal-error text-center">
        <img src="assets/img/error.png" class="mb-30" alt="error" />
        <h4 class="mb-15 ms-auto me-auto">
          {{ "COMMON_PAGE.INTERNAL_ERROR.DESC" | translate }}
        </h4>
        <a
          mat-raised-button
          class="imako mt-8"
          href="javascript:history.back();"
          >{{ "GeneralStrings.LABEL_BACK" | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
