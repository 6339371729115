<div class="not-network text-center">
  <img
    src="assets/img/not-network.png"
    class="mb-25"
    width="350"
    alt="not-network"
  />
  <h4 class="mb-15">{{ "COMMON_PAGE.NOT_NETWORK.TITLE" | translate }}</h4>
  <p class="ms-auto me-auto">
    {{ "COMMON_PAGE.NOT_NETWORK.DESC" | translate }}
  </p>
  <a mat-raised-button class="imako mt-8" href="javascript:history.back();">{{
    "GeneralStrings.LABEL_BACK" | translate
  }}</a>
</div>
